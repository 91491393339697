<template>
  <div class="topicSelectionList">
    <div class="tips">
      评选为优秀话题可获得【推医汇】公众号的特别报道，提升学术影响力
    </div>
    <div class="search_block">
      <div class="search_input">
        <el-input
          v-model="search.filter_text"
          @input="searchHandle"
          placeholder="请输入会议标题、专家姓名"
        >
          <template #suffix>
            <el-icon><Search /></el-icon>
          </template>
        </el-input>
      </div>
      <div class="search_label">
        <div
          class="search_label_item"
          :class="search.diseases_top_type == label ? 'colorRed' : ''"
          v-for="label in config.label"
          :key="label"
          @click="searchHandle(label, 'label')"
        >
          {{ label }}
        </div>
      </div>
    </div>
    <div class="topicSelectionList_main">
      <ListScroll
        :url="$urls.topicSelection.list"
        ref="ListScroll"
        @receiveResponse="receiveResponse"
      >
        <template v-slot:listItem="{ cItems }">
          <div style="padding: 0.3rem">
            <div
              class="topic_selection_item"
              @click.stop="handleDetail(cItems)"
            >
              <div class="topic_selection_item_left">
                <div class="title">
                  {{ cItems.title }}
                </div>
                <div class="name">参会嘉宾：{{ cItems.users }}</div>
              </div>
              <div
                class="topic_selection_item_right"
                :class="my_num >= 10 ? 'topic_selection_item_right_dis' : ''"
              >
                <!-- @click.stop="handleLike(cItems)" -->
                我要点赞
              </div>
            </div>
          </div>
        </template>
      </ListScroll>
    </div>
  </div>
</template>
<script>
import { Search } from "@element-plus/icons";
import ListScroll from "@/components/unit/ListScroll";
import wx from "@/common/weixin";
import weixin from "weixin-js-sdk";
export default {
  name: "topicSelectionList",
  components: {
    Search,
    ListScroll,
  },
  data() {
    return {
      config: {
        label: [],
      },
      search: {
        filter_text: "",
        diseases_top_type: "全部",
      },
      my_num: "",

      timer: {
        updateTime: 0, // 更新时间
        space: 100, //
        instance: null,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.share();
    }, 500);
  },
  methods: {
    receiveResponse(response) {
      this.my_num = response.my_num;
      this.config.label = ["全部", ...response.diseases_top_type_list];
    },
    getMyLikeNum() {},
    getSearch() {
      let search = { ...this.search };
      return search;
    },

    searchHandle(label, type) {
      if (type == "label") {
        this.search.diseases_top_type = label;
      }
      if (this.timer.updateTime > 0) {
        this.timer.updateTime = Date.now();
      } else {
        this.timer.updateTime = Date.now();
        this.initTimer();
      }
    },
    initTimer() {
      if (this.timer.instance) {
        clearTimeout(this.timer.instance);
        this.timer.instance = null;
      }
      if (Date.now() - this.timer.updateTime > this.timer.space) {
        this.timer.updateTime = 0;
        let search = this.getSearch();
        this.$refs.ListScroll && this.$refs.ListScroll.searchData(search);
        return;
      }
      setTimeout(() => {
        this.initTimer();
      }, 100);
    },
    handleLike() {
      console.log("object :>> ", "点赞");
    },
    handleDetail(cItems) {
      this.$router.push({
        path: "/topicSelectionDetail",
        query: {
          id: cItems.id,
        },
      });
    },
    share() {
      let params = {
        title: "骨转百讯-肿瘤免疫临床实践热门话题评选活动",
        desc: "骨转百讯-肿瘤免疫临床实践热门话题评选活动火热进行中，快来点赞吧",
        url: "",
        logo: "https://files.medflying.com/files/骨转百讯/560/png/骨转百讯公众号头像-23_11_20.png",
      };
      // console.log(params);
      weixin.ready(() => {
        wx.sharePageMessage(params);
      });
      // wx.sharePageMessage(params);
    },
  },
};
</script>
<style>
.topicSelectionList {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.topicSelectionList .tips {
  text-align: center;
  background: #f8edf2;
  font-size: 0.8rem;
  margin-top: 0.2rem;
  padding: 0.4rem 0;
}
.topicSelectionList .search_block {
  padding: 1rem 1rem 0.5rem 1rem;
}
.topicSelectionList .search_label {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  align-items: center; /* 控制每行元素的垂直对齐方式 */
}
.topicSelectionList .search_label_item {
  background: #ccc;
  padding: 0.5rem 0.8rem;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 0.8rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.topicSelectionList .colorRed {
  background: #e64638;
}
.topicSelectionList_main {
  padding: 0 1rem 1rem;
  flex: 1 0 0;
  overflow: hidden;
}
.topic_selection_item {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  margin-bottom: 0.4rem;
  border-radius: 0.5rem;
  padding: 0.4rem;
  align-items: center;
  font-size: 0.8rem;
  box-shadow: 0 0 0.3rem 0.05rem #ccc;
}
.topic_selection_item_left {
  /* display: flex; */
  flex: 1 0 0;
  line-height: 1.5;
}
.topic_selection_item .title {
  color: #e64638;
  margin-bottom: 0.4rem;
  font-size: 0.9rem;
}
.topic_selection_item_right {
  /* flex: 4.5rem 0 0; */
  background: #e64638;
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-left: 0.5rem;
}
.topic_selection_item_right_dis {
  background: #a0a0a0 !important;
}
.topicSelectionList_main .listScroll_main {
  flex: 1 0 0 !important;
  min-height: auto !important;
}
.topicSelectionList_main .el-input__suffix {
  /* top: 2px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>